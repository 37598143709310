import { Component, OnInit,Input  } from '@angular/core';

@Component({
  selector: './app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent{

  // Pie
  @Input() public pieChartLabels: string[] = ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  @Input() public pieChartData: number[] = [300, 500, 100];
  public pieChartType = 'pie';
    // events
    public chartClicked(e: any): void {
      console.log(e);
    }
  
    public chartHovered(e: any): void {
      //console.log(e);
    }

}
