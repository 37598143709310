import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponent } from './shared.component';
import { ChartsModule } from 'ng2-charts';
// own component //
import { LineChartComponent } from '../views/reports/line-chart/line-chart.component';
import { BarChartComponent } from '../views/reports/bar-chart/bar-chart.component';
import { PieChartComponent } from '../views/reports/pie-chart/pie-chart.component';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule
  ],
  declarations: [SharedComponent , LineChartComponent, BarChartComponent, PieChartComponent],
  exports: [ 
    SharedComponent, LineChartComponent, BarChartComponent, PieChartComponent
  ]
})
export class SharedModule { }
