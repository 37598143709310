import { Component, OnInit, Input  } from '@angular/core';

@Component({
  selector: './app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent  {
  // lineChart
  @Input() public lineChartData: Array<any> = [
    {data: [65, 59, 80, 81, 56, 55, 40], label: 'Value'}
  ];
  @Input() public lineChartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartOptions: any = {
    animation: false,
    responsive: true
  };
  public lineChartColours: Array<any> = [
    { 
      // pink
      backgroundColor: 'rgb(255,161,181,0.8)',
      borderColor: 'rgb(231,108,129,0.2)',
      pointBackgroundColor: 'rgb(231,108,129,0.6)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#ca4542'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    //console.log(e);
  }

}
