import { Component, Output, EventEmitter, Directive } from '@angular/core';
import { FormControl,  FormGroup   } from '@angular/forms';
import { ecmService } from '../../services/ecm.service';
import { CookieService  } from 'ngx-cookie-service';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'customerdashboard.component.html',
  styleUrls: ['customerdashboard.component.scss']
})
@Directive({
  selector: '[onCreate]'
})
export class CustomerdashboardComponent {
  gridApi = {};
  gridColumnApi = {} ;
  defaultColDef = {} ;
  columnDefs = {};
  reportAll = [];
  rowData = {};
  byPassGraph = {};
  currentDate :any;
  currentTime :any;
  iconReportType = {
    "0":`<i class="fa fa-table"></i> `,
    "1":`<i class="fa fa-area-chart"></i> `,
    "2":`<i class="fa fa-bar-chart"></i>`,
    "3":'<i class="fa fa-pie-chart"></i>'
  }
  colReportType = {
    "0":`col-xl-4`,
    "1":`col-xl-4`,
    "2":`col-xl-4`,
    "3":'col-xl-4'
  }
  shortCodeFilter = {};
  companyName = window['environment']['companyName'];
  renderChart = {};
  @Output() onCreate: EventEmitter<any> = new EventEmitter<any>();
  constructor(private ecm : ecmService, private cookie : CookieService ) {



  }
  ngOnInit() {
    this.onCreate.emit('dummy');
    this.ecm.path = '/advancereport/report';
    this.ecm.ecmGet([]).subscribe(
          data => {
               this.reportAll = data;
               this.setGrid();
          },
          error => {
               console.log(error);
          }
        );
        let dateFormat = require('dateformat');
        setInterval(() => {
          this.currentDate = dateFormat(new Date(),"ddd  dS mmm  yyyy");
          this.currentTime = dateFormat(new Date(),"h:MM:ss");
        }, 1);
    }
    onBtExport(i) {
      var params = {
        skipHeader: false,
        columnGroups: false,
        skipFooters: true,
        skipGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        allColumns: true,
        onlySelected: false,
        suppressQuotes: false,
        fileName: 'report-result'+i,
        columnSeparator: ''
      };
      this.gridApi[i].exportDataAsCsv(params);

    }

    setGrid(){
      let gridApi = this.gridApi;
      let object = this;
      this.reportAll.map(function(value, index){
        console.log(value, index)
        if(value.report_type == "0"){
          object.gridApi[index] = {};
          object.rowData[index] = [];
        } else {
          object.renderChart[index] = -1;
          object.byPassGraph[index] = {};
          object.byPassGraph[index].x = [];
          object.byPassGraph[index].y = [ {'data': [], 'label': 'Value'} ];
        }
        object.shortCodeFilter[index] = object.transformShortcode(value.shortcode);
        let page = 1
        object.getReportData(value.report_id, {}, value.report_type, page ,index );
      });
      console.log("setGrid");

      return true;
    }

    onGridReady(i,params) {
      this.gridApi[i] = params.api;
      this.gridColumnApi[i] = params.columnApi;
      this.defaultColDef[i] = { resizable: true };
    }
    getReportData(id,object, report_type, page ,index){
      if(!id) return false;
      let classObject = this;
      this.ecm.path = '/advancereport/generateReport/'+page;
      let post = {};
      post['report_id'] = id;
      post['report_filter'] = object;
      this.ecm.ecmPost(post, []).subscribe(
            data => {
              if(data.result.length > 0){
                // console.log('--> getReportData: ', data.result);
                 this.setGridResult(data.result, report_type, index);
                 page++;
                 classObject.getReportData(id,object, report_type, page ,index);
              }
            },
            error => {
                 console.log(error);
            }
          );

    }

    setGridResult(result, report_type ,index){
       let object = this;
       if(report_type == "0") {
          object.columnDefs[index] = [];
          let header = Object.keys(result[0]);
          object.rowData[index] = this.rowData[index].concat(result);
          header.forEach((fieldName) => {
              let colunm = {  headerName: fieldName, field: fieldName, sortable: true, filter: true, suppressSizeToFit: true };
              object.columnDefs[index].push(colunm);
          });
        } else {
          object.renderChart[index] = report_type;
          for (let i in result) {
            object.byPassGraph[index]['x'].push(result[i]['x']);
            object.byPassGraph[index]['y'][0]['data'].push(parseInt(result[i]['y']));
          }
        }
    }

    transformShortcode(value): any {
      return typeof value ==='string' &&  value != "" ? JSON.parse(value.replace(/'/g, '"')) : value;
     }

     ukDateFormat(e){
       let dateFormat = require('dateformat');
       let date = new Date(e);
       let info = dateFormat(date,"dd-mm-yyyy");// https://www.npmjs.com/package/dateformat this is plugin
       return info;
     }
     excuteByFilter(i) {
       let report =this.reportAll[i];
       let object = { "shortcode" : report.shortcode}
       if(report.report_type == "0"){ 
        this.gridApi[i].showLoadingOverlay();
        this.rowData[i] = [];
      } else {
        this.renderChart[i] = -1;
        this.byPassGraph[i] = {};
        this.byPassGraph[i].x = [];
        this.byPassGraph[i].y = [ {'data': [], 'label': 'Value'} ];
      }
       this.getReportData(report.report_id, object, report.report_type, 1 ,i);

     }




}

