import { Injectable } from '@angular/core';
//import { Observable } from "rxjs/Rx";
import { Router } from '@angular/router';
import { throttleTime, map, scan } from 'rxjs/operators';
import { Headers, Http, Response} from "@angular/http";
import { HttpHeaders } from '@angular/common/http';
import { CookieService  } from 'ngx-cookie-service';
import {throwError} from "rxjs";
import {catchError} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ecmService {
  public url = '';
  public path = '';
  public header;

  constructor(  public http: Http, private cookie: CookieService, private route : Router ) { 
    this.url = window['environment']['apiUrl'];
    this.header = new Headers(); 
    
    this.setHeader( 'Content-Type', 'application/json');
    this.setHeader( 'Cache-Control', 'no-cache');
  }

  private setHeaderAuth() {
    
    if (this.cookie.check("token")) {
      let token = this.cookie.get("token");
      if(!!token) {
        this.setHeader( "Authorization", "Bearer " + token);
      }
    }
    
  }

  public setHeader(key, value){
     return this.header.set(key, value);
  }

  public getHeader(){
     return this.header;
  }

  public ecmPost(body : any, options : any){
    
    this.setHeaderAuth();
    let fullOptions ={ headers: this.header, params: options };

    return this.http.post(this.url+this.path, body , fullOptions).pipe(
      map((response: any) => response.json()),
      catchError((error) => {

        if(error.status == 401) {
          // Unauthorized
          this.route.navigate(['/login']);
        }

        return throwError(error);
      })
    );

    //return this.http.post(this.url+this.path, body , fullOptions).pipe(map((response: any) => response.json()));
  }

  public ecmGet(options : any){

  
    this.setHeaderAuth();
    let fullOptions = { headers: this.header, params: options };

    return this.http.get(this.url+this.path, fullOptions).pipe(
      map((response: any) => response.json()),
      catchError((error) => {

        if (error.status == 401) {
          // Unauthorized
          this.route.navigate(['/login']);
        }

        return throwError(error);

      })
    );

    //return this.http.get(this.url+this.path, fullOptions).pipe(map((response: any) => response.json()));

   }

  public ecmDelete(options:any){

    this.setHeaderAuth();

    let fullOptions = { headers: this.header, params: options };

    return this.http.delete(this.url+this.path, options).pipe(
      map((response: any) => response.json()),
      catchError((error) => {

        if (error.status == 401) {
          // Unauthorized
          this.route.navigate(['/login']);
        }

        return throwError(error);

      })
    );
    
    //return this.http.delete(this.url+this.path, options).pipe(map((response: any) => response.json()));

  }

  public ecmPut(body : any, options : any){

    this.setHeaderAuth();
    let fullOptions = { headers: this.header, params: options };

    return this.http.put(this.url+this.path, body, options).pipe(
      map((response: any) => response.json()),
      catchError((error) => {

        if (error.status == 401) {
          // Unauthorized
          this.route.navigate(['/login']);
        }

        return throwError(error);

      })
    );

    //return this.http.put(this.url+this.path, body, options).pipe(map((response: any) => response.json()));

  }

}
