import { Component } from '@angular/core';
import { FormControl,  FormGroup   } from '@angular/forms';
import { Router } from '@angular/router';
import { ecmService } from '../../services/ecm.service';
import { CookieService  } from 'ngx-cookie-service';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent { 
  loginForm = new FormGroup({
    usernameControl: new FormControl(''),
    passwordControl: new FormControl(''),
  });


  responeData : any;
  showSpinner : any;

  constructor(private route : Router, private ecm : ecmService, private cookie : CookieService ) {

    this.ecm.path = '/auth';
    this.showSpinner = false;
    localStorage.setItem('token',"");

  }

  onSubmit() {
      this.showSpinner = true;
      this.responeData = null;
     
       let post = {
         'username': this.loginForm.value.usernameControl,
         'password': this.loginForm.value.passwordControl
       };

      
      this.cookie.delete("token");  //Delete current token from cookie.
      

       this.ecm.ecmPost(post, []).subscribe(
         data => {
           
            //{result_code: 0, msg: "Successfully logged in", token: "", auth_type: "bearer", expire_hours: 12, expire_time: "19-08-06 02:07:13", expire_timestamp: 1565032033}
            this.responeData = data;
           
            if (data.result_code == 0) {

              var expDate = new Date(data.expire_timestamp * 1000);
              this.cookie.set("token", data.token, expDate );

             this.route.navigate(['/']);
            
            } else {
              this.showSpinner = false;
            }

            console.log(data);
         }, 
         error => {
            this.showSpinner = false;
            console.log(error);
         }
      );

     return false;
  }
}
