import { Component, OnInit ,Input } from '@angular/core';


@Component({
  selector: './app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent  {

 // barChart
 public barChartOptions: any = {
  scaleShowVerticalLines: false,
  responsive: true
  };
  @Input() public barChartLabels: string[] = ["2019-1", "2019-2", "2019-3", "2019-4", "2019-5", "2019-6", "2019-7", "2019-8", "2019-9", "2019-10"];
  public barChartType = 'bar';
  public barChartLegend = true;

  @Input() public barChartData: any[] = [
    {data: [500, 1000, 1200, 20, 444, 1200, 432, 879, 8523, 555], label: 'Value'}
  ];

  @Input() public text = "YUI";

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    //console.log(e);
  }
}
